import { Injectable, inject } from "@angular/core";
import { Auth, GoogleAuthProvider, IdTokenResult, User, UserCredential, authState, createUserWithEmailAndPassword, signInWithPopup, signOut, updateProfile, user } from "@angular/fire/auth";
import { idTokenResult } from "@angular/fire/auth-guard";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class FirebaseAuthService {

    public readonly isLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);

    public readonly user: ReplaySubject<User> = new ReplaySubject(1);

    public readonly idToken: ReplaySubject<string> = new ReplaySubject(1);

    public readonly role: ReplaySubject<string> = new ReplaySubject(1);

    private readonly auth: Auth = inject(Auth);
    private readonly authState: Observable<User> = authState(this.auth);
    private readonly userState: Observable<User> = user(this.auth);
    private readonly idTokenResult: Observable<IdTokenResult> = idTokenResult(this.authState);

    public constructor() {
        this.authState.subscribe((user: User) => this.authStateChanged(user));
        this.userState.subscribe((user: User) => this.userChanged(user));
        this.idTokenResult.subscribe((idTokenResult: IdTokenResult) => this.idTokenResultChanged(idTokenResult));
    }

    public async register(name: string, email: string, password: string): Promise<boolean> {
        const userCredential: UserCredential = await createUserWithEmailAndPassword(this.auth, email, password);

        if (!!userCredential?.user?.uid) {
            await updateProfile(userCredential.user, { displayName: name });

            return true;
        }

        return false;
    }

    public async logout(): Promise<boolean> {
        await signOut(this.auth);

        return true;
    }

    public async loginWithGoogle(): Promise<boolean> {
        const userCredential: UserCredential = await signInWithPopup(this.auth, new GoogleAuthProvider());

        return !!userCredential?.user?.uid;
    }

    private authStateChanged(user: User): void {
        this.isLoggedIn.next(!!user?.uid);
    }

    private userChanged(user: User): void {
        this.user.next(user);
    }

    private idTokenResultChanged(idTokenResult: IdTokenResult): void {
        this.idToken.next(idTokenResult?.token);
        this.role.next(idTokenResult?.claims?.role);
    }

    public setRole(role: string): void {
        this.role.next(role);
    }
}
