import { Directive, HostListener, Input } from "@angular/core";
import { ToasterService } from "../../core/services/toaster.service";

@Directive({
    selector: "[copyToClipboard]"
})
export class CopyToClipboardDirective {

    @Input("textToCopy") textToCopy: string;

    public constructor(
        private readonly toasterService: ToasterService
    ) {
        // empty
    }

    @HostListener("click") onClick() {
        this.copyToClipboard();
    }

    public async copyToClipboard(): Promise<void> {
        await navigator.clipboard.writeText(this.textToCopy);

        this.toasterService.success("Copied to clipboard!");
    }
}