import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { RequestTrackerService } from "../../core/services/request-tracker.service";

@Injectable()
export class RequestLifecycleInterceptor implements HttpInterceptor {

    public constructor(
        private requestTrackerService: RequestTrackerService
    ) {
        // empty
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestTrackerService.startRequest();

        return next.handle(req).pipe(
            finalize(() => {
                this.requestTrackerService.endRequest();
            })
        );
    }
}
