<div class="row top-section">
    <div class="col main-wrapper">
        <div class="card card-element">
            <div class="card-body body-element">
                <div class="row">
                    <div class="col">
                        <span
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Features, new flows, provider integrations..."
                        >
                            <img
                                src="../../../../../assets/images/roles/atlas.svg"
                                width="100"
                                height="100"
                                class="d-inline-block align-top"
                                alt="firsty"
                            />
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="card-atlas">Atlas Init.</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-element">Feature Development</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-role">Melike / Utku</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-element">
            <div class="card-body body-element">
                <div class="row">
                    <div class="col">
                        <span
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Bug fixes, API improvements, backoffice..."
                        >
                            <img
                                src="../../../../../assets/images/roles/apollo.svg"
                                width="100"
                                height="100"
                                class="d-inline-block align-top"
                                alt="firsty"
                            />
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="card-apollo">Apollo Ops.</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-element">Big Fixes & Improvements</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-role">Utku</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-element">
            <div class="card-body body-element">
                <div class="row">
                    <div class="col">
                        <span
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Database improvements, scalability, refactoring..."
                        >
                            <img
                                src="../../../../../assets/images/roles/sisyphus.svg"
                                width="100"
                                height="100"
                                class="d-inline-block align-top"
                                alt="firsty"
                            />
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="card-sisyphus">Sisyphus Div.</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-element">Tech Debt</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text-role">Melike</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
