import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Toast } from "../../shared/models/toast";

@Injectable({
    providedIn: "root"
})
export class ToasterService {

    private readonly _toasts: ReplaySubject<Toast> = new ReplaySubject(1);

    public get toasts(): ReplaySubject<Toast> {
        return this._toasts;
    }

    public info(message: string): void {
        this._toasts.next({
            header: "",
            message: message,
            type: "",
            delay: 5000
        });
    }

    public success(message: string): void {
        this._toasts.next({
            header: "",
            message: message,
            type: "success",
            delay: 5000
        });
    }

    public warn(message: string): void {
        this._toasts.next({
            header: "",
            message: message,
            type: "warning",
            delay: 5000
        });
    }

    public error(message: string): void {
        this._toasts.next({
            header: "",
            message: message,
            type: "danger",
            delay: 10000
        });
    }
}
