import { Component, OnDestroy, OnInit } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FirebaseAuthService } from "src/app/core/services/firebase-auth.service";
import { ApiResponse } from "src/app/shared/models/api-response";
import * as bootstrap from "bootstrap";
import { StatsService } from "src/app/core/services/stats.service";

@Component({
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {

    public esimCount: number = 0;
    public advertisementCount: number = 0;
    public premiumCount: number = 0
    public ultraCount: number = 0;
    public liteCount: number = 0;
    public esimAssignments: number = 0;
    public partnerUsers: number = 0;
    public lastUsers: number = 0;
    public adsWatched: number = 0;
    public promoCodes: number = 0;
    public paidCount: number = 0;
    public blockCount: number = 0;

    private _uid : string;

    public get uid() : string {
        return this._uid;
    }

    private _token : string;

    public get token() : string {
        return this._token;
    }

    private _isAdmin: boolean;

    public get isAdmin(): boolean {
        return this._isAdmin;
    }

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    public constructor(
        private readonly firebaseAuthService: FirebaseAuthService,
        private readonly statsService: StatsService,
    ) { }

    public ngOnInit(): void {
        this.firebaseAuthService.user
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: User) => {
                this._uid = value.uid;
            });

        this.firebaseAuthService.idToken
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: string) => {
                this._token = value;
            });

        this.firebaseAuthService.role
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: string) => {
                this._isAdmin = (value === "admin");
            });

            this.getEsimCount();
            this.initialiseToolTips();
    }

    private getEsimCount(): void {
        this.statsService.getDashboardStats(this.uid)
            .subscribe((response: any) => {
                this.esimCount = response.esimCount;
                this.advertisementCount = response.advertisementCount;
                this.premiumCount = response.premiumCount;
                this.ultraCount = response.ultraCount;
                this.liteCount = response.litePlanCount;
                this.esimAssignments = response.esimAssignments;
                this.partnerUsers = response.partnerUsers;
                this.lastUsers = response.lastUsers;
                this.adsWatched = response.adsWatched;
                this.promoCodes = response.promoCodes;
                this.paidCount = response.paidCount;
                this.blockCount = response.blockCount;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initialiseToolTips(): void {
        setTimeout(() => {
          const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
          })
        });
      }
}
