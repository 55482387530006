<div class="container text-center">
    <div style="margin-top: 15px;">
        <h1>Realtime Statistics</h1>
    </div>
    <div class="row top-section">
        <div class="col main-wrapper">
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="eSIMs left available for assignment"
                    >
                        <i class="bi bi-sim-fill card-a"></i>
                    </span>
                    <span class="text-element">{{ esimCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="eSIMs assigned in the last 24 Hours"
                    >
                    <i class="bi bi-person-badge-fill card-b"></i>
                </span>
                    <span class="text-element">{{ esimAssignments }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Users registered in the last 24 Hours"
                    >
                    <i class="bi bi-person-fill-add card-c"></i>
                </span>
                    <span class="text-element">{{ lastUsers }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Partner users registered in the last 24 Hours"
                    >
                    <i class="bi bi-briefcase-fill card-d"></i>
                </span>
                    <span class="text-element">{{ partnerUsers }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row top-section">
        <div class="col main-wrapper">
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Users watched advertisement in the last 24 Hours"
                    >
                    <i class="bi bi-eye-fill card-e"></i>
                </span>
                    <span class="text-element">{{ adsWatched }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Users paid for a subscription in the last 24 Hours"
                    >
                    <i class="bi bi-cash-stack card-f"></i>
                </span>
                    <span class="text-element">{{ paidCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Users used a promo code in the last 24 Hours"
                    >
                    <i class="bi bi-ticket-detailed-fill card-g"></i>
                </span>
                    <span class="text-element">{{ promoCodes }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Users were blocked in the last 24 Hours"
                    >
                    <i class="bi bi-ban card-h"></i>
                </span>
                    <span class="text-element">{{ blockCount }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row top-section" style="margin-bottom: 30px">
        <div class="col main-wrapper">
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Advertisement Subscriptions"
                    >
                    <i class="bi bi-database-fill card-i"></i>
                </span>
                    <span class="text-element">{{ advertisementCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Lite Subscriptions"
                    >
                    <i class="bi bi-database-fill card-j"></i>
                </span>
                    <span class="text-element">{{ liteCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Comfort Plus Subscriptions"
                    >
                    <i class="bi bi-database-fill card-k"></i>
                </span>
                    <span class="text-element">{{ premiumCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active First Class Subscriptions"
                    >
                    <i class="bi bi-database-fill card-l"></i>
                </span>
                    <span class="text-element">{{ ultraCount }}</span>
                </div>
            </div>
        </div>
    </div>
    <h1>Current Backoffice Role Rotation</h1>
    <app-roles></app-roles>
</div>
