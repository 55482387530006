import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../../shared/models/api-response";
import { Member } from "../../shared/models/member";

@Injectable({
    providedIn: "root"
})
export class StatsService {

    public constructor(
        private readonly httpClient: HttpClient
    ) { }

    public getDashboardStats(uid: string): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/dashboard/${uid}/stats`);
    }
}
